import React, { useState } from 'react';

const WebLogin = ({ lang }) => {

    const [userName, setUserName] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [userOTP, setUserOTP] = useState("");

    const [userStatus, setUserStatus] = useState(0);    // 0 - Idle, 1 - Waiting, 2 - Open, 3 - Closed/Timeout, 4 - Duress

    const [loginType, setLoginType] = useState("classic");

    async function doPasswordlessLogin() {

        // Send notification
        await fetch(`https://demo.zoftoken.com/api/send?id=${userName}`);

        // Wait for reply
        let showProgress = setTimeout(() => setUserStatus(1), 1000);

        const res = await fetch(`https://demo.zoftoken.com/api/check?id=${userName}`);
        const reply = await res.json();

        clearTimeout(showProgress);
        if (reply.status === "open") setUserStatus(2);
        else if (reply.status === "duress") setUserStatus(4);
        else setUserStatus(3);

    }

    async function doModernLogin() {

        if (userName !== userPassword) setUserStatus(3);
        else {
            doPasswordlessLogin();
        }
    }

    async function doClassicLogin() {
        if (userName !== userPassword) setUserStatus(3);
        else {
            const res = await fetch(`https://demo.zoftoken.com/api/code?id=${userName}&code=${userOTP}`);
            const reply = await res.json();

            if (reply.status === "valid") setUserStatus(2);
            else if (reply.status === "duress") setUserStatus(4);
            else setUserStatus(3);
        }
    }

    return (
        <div>
            <div className="select is-secondary is-small">
                <select onChange={(e) => { setLoginType(e.target.value); setUserStatus(0) }}>
                    <option value="classic">{lang === "en" ? "Traditional 2FA" : "2FA tradicional"}</option>
                    <option value="modern">{lang === "en" ? "Modern 2FA" : "2FA moderno"}</option>
                    <option value="passwordless">{lang === "en" ? "Passwordless" : "Sin contraseña"}</option>
                </select>
            </div>
            {loginType === "passwordless" &&
                <div className="box">
                    <label className="label">{lang === "en" ? "User name" : "Nombre de usuario"}</label>

                    <div className="field has-addons">
                        <div className="control is-expanded">
                            <input className="input" type="text" onChange={(e) => setUserName(e.target.value)} />
                        </div>
                        <div className="control">
                            <button className="button is-primary" onClick={doPasswordlessLogin}>{lang === "en" ? "Login" : "Ingresar"}</button>
                        </div>
                    </div>
                </div>
            }
            {loginType === "modern" &&
                <div className="box">
                    <div className="field">
                        <label className="label">{lang === "en" ? "User name" : "Nombre de usuario"}</label>
                        <div className="control">
                            <input className="input" type="text" onChange={(e) => setUserName(e.target.value)} />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">{lang === "en" ? "Password" : "Contraseña"}</label>
                        <div className="control">
                            <input className="input" type="password" onChange={(e) => setUserPassword(e.target.value)} />
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                            <button className="button is-primary" onClick={doModernLogin}>{lang === "en" ? "Login" : "Ingresar"}</button>
                        </div>
                    </div>
                </div>
            }
            {loginType === "classic" &&
                <div className="box">
                    <div className="field">
                        <label className="label">{lang === "en" ? "User name" : "Nombre de usuario"}</label>
                        <div className="control">
                            <input className="input" type="text" onChange={(e) => setUserName(e.target.value)} />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">{lang === "en" ? "Password" : "Contraseña"}</label>
                        <div className="control">
                            <input className="input" type="password" onChange={(e) => setUserPassword(e.target.value)} />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">{lang === "en" ? "Security code" : "Código de seguridad"}</label>
                        <div className="control">
                            <input className="input" type="password" onChange={(e) => setUserOTP(e.target.value)} />
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                            <button className="button is-primary" onClick={doClassicLogin}>{lang === "en" ? "Login" : "Ingresar"}</button>
                        </div>
                    </div>
                </div>
            }
            {userStatus === 1 &&
                <div className="box is-size-5 has-text-centered">
                    {lang === "en" ? "Waiting for token activation" : "Esperando la activación del token"}
                    <progress className="progress is-info" />
                </div>
            }
            {userStatus === 2 &&
                <div className="has-text-centered">
                    <span className="tag is-large is-success is-rounded">{lang === "en" ? "Login authorized" : "Ingreso autorizado"}</span>
                </div>
            }
            {userStatus === 3 &&
                <div className="has-text-centered">
                    <span className="tag is-large is-warning is-rounded">{lang === "en" ? "Login not authorized" : "Ingreso no autorizado"}</span>
                </div>
            }
            {userStatus === 4 &&
                <div className="has-text-centered">
                    <span className="tag is-large is-danger is-rounded">{lang === "en" ? "Login authorized under duress" : "Ingreso autorizado bajo coacción"}</span>
                </div>
            }
        </div>
    );

}

export default WebLogin;
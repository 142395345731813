import React, { useState, useEffect } from 'react';

let poll = false;
let user = "";

const CreditCard = ({ lang }) => {

    const [userName, setUserName] = useState("");
    const [userStatus, setUserStatus] = useState(0);    // 0 - Idle, 1 - Waiting, 2 - Open, 3 - Closed/Timeout, 4 - Duress

    async function startPolling() {

        user = userName;

        const res = await fetch(`https://demo.zoftoken.com/api/status?id=${userName}`);
        const reply = await res.json();

        if (reply.status === 1) setUserStatus(2);
        else if (reply.status === 4) setUserStatus(4);
        else setUserStatus(3);

        while (poll) {
            try {
                const res = await fetch(`https://demo.zoftoken.com/api/status?id=${userName}&long`);
                const reply = await res.json();

                if (reply.status !== "unchanged") {
                    if (reply.status === "open") setUserStatus(2);
                    else if (reply.status === "duress") setUserStatus(4);
                    else setUserStatus(3);
                }
            }
            catch { }
        }
    }

    useEffect(() => {
        poll = true;
        return () => {
            poll = false;
            fetch(`https://demo.zoftoken.com/api/status?id=${user}&kill`);
        }
    }, []);

    return (
        <div>
            <div className="box">
                <label className="label">{lang === "en" ? "Card identification" : "Identificación de la tarjeta"}</label>

                <div className="field has-addons">
                    <div className="control is-expanded">
                        <input className="input" type="text" onChange={(e) => setUserName(e.target.value)} />
                    </div>
                    <div className="control">
                        <button className="button is-primary" onClick={startPolling}>{lang === "en" ? "Process" : "Procesar"}</button>
                    </div>
                </div>
            </div>
            {userStatus === 2 &&
                <div className="has-text-centered">
                    <span className="tag is-large is-success is-rounded">{lang === "en" ? "Card enabled" : "Tarjeta habilitada"}</span>
                </div>
            }
            {userStatus === 3 &&
                <div className="has-text-centered">
                    <span className="tag is-large is-warning is-rounded">{lang === "en" ? "Card disabled" : "Tarjeta deshabilitada"}</span>
                </div>
            }
            {userStatus === 4 &&
                <div className="has-text-centered">
                    <span className="tag is-large is-danger is-rounded">{lang === "en" ? "Card under duress" : "Tarjeta bajo coacción"}</span>
                </div>
            }
        </div>
    );
}

export default CreditCard;
import React, { useState } from 'react';

const CallCenter = ({ lang }) => {

    const [userName, setUserName] = useState("");
    const [userStatus, setUserStatus] = useState(0);    // 0 - Idle, 1 - Waiting, 2 - Open, 3 - Closed/Timeout, 4 - Duress
    const [userCode, setUserCode] = useState("");
    const [userCodeValidation, setUserCodeValidation] = useState(false);

    async function doOnlineVerification() {

        setUserCodeValidation(false);

        // Send notification
        await fetch(`https://demo.zoftoken.com/api/send?id=${userName}`);

        // Wait for reply
        let showProgress = setTimeout(() => setUserStatus(1), 1000);

        const res = await fetch(`https://demo.zoftoken.com/api/check?id=${userName}`);
        const reply = await res.json();

        clearTimeout(showProgress);
        if (reply.status === "open") setUserStatus(2);
        else if (reply.status === "duress") setUserStatus(4);
        else setUserStatus(3);

    }

    async function doCodeVerification() {

        const res = await fetch(`https://demo.zoftoken.com/api/code?id=${userName}&code=${userCode}`);
        const reply = await res.json();

        if (reply.status === "valid") setUserStatus(2);
        else if (reply.status === "duress") setUserStatus(4);
        else setUserStatus(3);

        setUserCodeValidation(false);
    }

    return (
        <div>
            <div className="box">
                <label className="label">{lang === "en" ? "User name" : "Nombre de usuario"}</label>

                <div className="field has-addons">
                    <div className="control is-expanded">
                        <input className="input" type="text" onChange={(e) => setUserName(e.target.value)} />
                    </div>
                    <div className="control">
                        <button className="button is-primary" onClick={doOnlineVerification}>{lang === "en" ? "Online" : "En línea"}</button>
                    </div>
                    <div className="control">
                        <button className="button is-info" onClick={() => { setUserCodeValidation(true); setUserStatus(0); }}>{lang === "en" ? "Code" : "Código"}</button>
                    </div>
                </div>
            </div>
            {userCodeValidation &&
                <div className="box">
                    <label className="label">{lang === "en" ? "User provided code" : "Código provisto por el usuario"}</label>

                    <div className="field has-addons">
                        <div className="control is-expanded">
                            <input className="input" type="text" onChange={(e) => setUserCode(e.target.value)} />
                        </div>
                        <div className="control">
                            <button className="button is-primary" onClick={doCodeVerification}>{lang === "en" ? "Verify" : "Verificar"}</button>
                        </div>
                    </div>
                </div>
            }
            {userStatus === 1 &&
                <div className="box is-size-5 has-text-centered">
                    {lang === "en" ? "Waiting for token activation" : "Esperando la activación del token"}
                    <progress className="progress is-info" />
                </div>
            }
            {userStatus === 2 &&
                <div className="has-text-centered">
                    <span className="tag is-large is-success is-rounded">{lang === "en" ? "User verified" : "Usuario verificado"}</span>
                </div>
            }
            {userStatus === 3 &&
                <div className="has-text-centered">
                    <span className="tag is-large is-warning is-rounded">{lang === "en" ? "User not verified" : "Usuario no verificado"}</span>
                </div>
            }
            {userStatus === 4 &&
                <div className="has-text-centered">
                    <span className="tag is-large is-danger is-rounded">{lang === "en" ? "User under duress" : "Usuario bajo coacción"}</span>
                </div>
            }
        </div>
    );

}

export default CallCenter;
import React, { useState } from 'react';

const Register = ({ lang }) => {

    const [userName, setUserName] = useState("");
    const [userEnrollData, setUserEnrollData] = useState();

    const [enrollType, setEnrollType] = useState("code");

    async function getEnrollmentData() {

        const res = await fetch(`https://demo.zoftoken.com/api/enroll?id=${userName}`);
        const reply = await res.json();

        setUserEnrollData(reply);
    }

    return (
        <>
            <div className="box">
                <label className="label">{lang === "en" ? "User name" : "Nombre de usuario"}</label>

                <div className="field has-addons">
                    <div className="control is-expanded">
                        <input className="input" type="text" onChange={(e) => setUserName(e.target.value)} />
                    </div>
                    <div className="control">
                        <button className="button is-primary" onClick={getEnrollmentData}>{lang === "en" ? "Register" : "Registrar"}</button>
                    </div>
                </div>
            </div>

            {userEnrollData && enrollType === "code" &&
                <div className="box is-size-3 has-text-centered">
                    {lang === "en" ? "Code" : "Código"}: {userEnrollData.code}
                </div>}

            {userEnrollData && enrollType === "qr" &&
                <div className="box is-size-3 has-text-centered">
                    <img src={userEnrollData.qrCode} alt="QR Code" />
                </div>}

            {userEnrollData && enrollType === "dl" &&
                <div className="box is-size-3 has-text-centered">
                    <div style={{ marginBottom: "15px" }}>
                        <a href={userEnrollData.dl}>
                            <span className="tag is-success is-medium is-rounded">
                                {lang === "en" ? "Enrollment link" : "Link de enrolamiento"}
                            </span>
                        </a>
                    </div>
                    <div className="is-size-7 has-text-centered" style={{ wordWrap: "break-word" }}>
                        {userEnrollData.dl}
                    </div>

                </div>}

            {userEnrollData &&
                <div className="select is-secondary is-small">
                    <select onChange={(e) => setEnrollType(e.target.value)}>
                        <option value="code">{lang === "en" ? "Code" : "Código"}</option>
                        <option value="qr">QR</option>
                        <option value="dl">Deeplink</option>
                    </select>
                </div>
            }
        </>
    );

}

export default Register;
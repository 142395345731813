import { useState, useEffect } from "react";
import Register from "./Register";
import CallCenter from "./CallCenter";
import WebLogin from "./WebLogin";
import CreditCard from "./CreditCard";

import "bulma/css/bulma.css";

function App() {

  const [page, setPage] = useState(1);
  const [language, setLanguage] = useState("es");

  var display;

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const lang = params.get("english");

    if (lang !== null) setLanguage("en");
  }, []);

  switch (page) {
    case 1: display = <Register lang={language} />; break;
    case 2: display = <WebLogin lang={language} />; break;
    case 3: display = <CallCenter lang={language} />; break;
    case 4: display = <CreditCard lang={language} />; break;
  }

  return (
    <div className="is-fullheight-100vh">
      <header className="column hero is-dark">
        <div className="hero-body">
          <p className="title">
            <img src="/images/zoftoken-logo.svg" alt="ZofToken" />
          </p>
          <p className="subtitle">
            Demo site
          </p>
        </div>
      </header>

      <main className="section  is-bordered">

        <div className=" tabs is-toggle is-centered is-fullwidth">
          <ul>
            <li className={page === 1 ? "is-active" : ""} onClick={() => setPage(1)}><a>{language === "en" ? "Configure user" : "Configurar usuario"}</a></li>
            <li className={page === 2 ? "is-active" : ""} onClick={() => setPage(2)}><a>{language === "en" ? "Web application" : "Aplicación web"}</a></li>
            <li className={page === 3 ? "is-active" : ""} onClick={() => setPage(3)}><a>{language === "en" ? "Call Center" : "Call Center"}</a></li>
            <li className={page === 4 ? "is-active" : ""} onClick={() => setPage(4)}><a>{language === "en" ? "Credit Card" : "Tarjeta de Crédito"}</a></li>
          </ul>
        </div>

        <div className="columns pt-6">
          <div className="column is-half is-offset-one-quarter">
            {display}
          </div>
        </div>

      </main>

      <footer className="footer is-bottom-fixed is-dark">
        <div className="content has-text-centered ">
          <p >
            {language === "en" ? "Learn more from us at " : "Visítenos en "} <strong><a href="https://www.zoftoken.com">www.zoftoken.com</a> </strong>
          </p>
        </div>
      </footer>

    </div>
  );
}

export default App;